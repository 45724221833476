<template>
    <div>
        <div class="subscribe-plan-mobile-view-firstCard">
            <div class="subscribe-plan-mobile-view-title">
              <div class="subscribe-plan-mobile-view-title-subscribe">
                <p class="subscribe-plan-mobile-view-title-subscribe-title text-center">{{ $t("start_watching_today") }}</p>
              </div>
            </div>
            <div class="subscribe-plan-mobile-view-subscribe-plan" v-if="localDisplayLang=='eng'">
                <div  class="subscribe-plan-mobile-view-card" v-for="(item,i) in appConfig.planBenefits.en[planIntervalChange]" :key="i">                   
                  <PlanTicks></PlanTicks>
                  <div>{{item}}</div>
                </div>                 
            </div>
            <div class="subscribe-plan-mobile-view-subscribe-plan" v-if="localDisplayLang=='ml'">
                <div  class="subscribe-plan-mobile-view-card" v-for="(item,i) in appConfig.planBenefits.ml[planIntervalChange]" :key="i">                   
                  <PlanTicks></PlanTicks>
                  <div>{{item}}</div>
                </div>                 
            </div>
        </div>
        <div class="subscribe-plan-mobile-view-subscribePlanCard">
              <div class="subscribe-plan-mobile-view-subscribePlanInnerCard" v-for="(item,index) in availablePlans" :key="index" @click="onChangePlanBenefits(item,index)" :class="`${selectedOption==index?'activeClass':'' }`">
                  <div v-html="getPlanInterval(item.planinterval)"></div>
                  <div class="subscribe-plan-mobile-view-currency">
                    <p>
            
                        <span v-if="item.planinterval === 'YEAR'" class="best-value-tag">Best Value</span>
                        <span v-html="currencyCodeSubscriber(item)"></span>
                    </p>
                    <div>
                        <!-- <MobileViewPlanTicks v-if="item.planinterval == planIntervalChange"></MobileViewPlanTicks> -->
                        <label class="radio-containers" >
                            <input type="radio" class="hidden-radio" v-model="selectedOption" name="radio" :value="index" :id="`radio-${index}`"/>                     
                            <span class="checkmark"></span>
                        </label>
                    </div>
                  </div>
              </div>               
        </div>
        <div class="subscribe-plan-mobile-view-button">
            <button class="subscribe-plan-mobile-view-button-buttonStatus" @click="openPaymentModal(selectedPlan)">{{ $t(buttonStatus) }}</button>
        </div>
        <div class="assistance">
            <P class="assistancetext"> For any assistance regarding your purchase or support, please reach out to us at <a target="_blank" style="color: red;" href="mailto:help@sainaplay.com">help@sainaplay.com</a></P>
        </div>
        <transition>
      <AuthPopup
        v-if="showAuthPopup"
        :formInfo="authInfo"
        :closePopup="() => closeAuthPopup(false)"
      />
    </transition>
    </div>
</template>
<script> 
import { mapGetters } from 'vuex';
import MobileViewPlanTicks from '../SvgImages/MobileViewPlanTicks.vue';
import {
    eventBus
} from "@/eventBus";
import UserAuth from '../Popups/Authentication/userAuth.vue';

export default {
    name:'mobileViewComponent',
    components:{
        PlanTicks: () => import(/* webpackChunkName: "planTicks" */ "@/components/SvgImages/PlanTicks.vue"),         
        MobileViewPlanTicks:() => import(/* webpackChunkName: "planTicks" */ "@/components/SvgImages/MobileViewPlanTicks.vue"),
         AuthPopup : () => import('@/components/Popups/Authentication/userAuth.vue')
    },
    computed:{
        ...mapGetters(['subscriberid','appConfig']),
    },
    props:["availablePlans"],
    data(){
        return{
            planInterval:0,
            enumDataset : { 
                "DAY": "DAY",
                "WEEK": "WEEK",
                "MONTH": " 1 Month",
                "QUARTER": "3 Months",
                "HALFYEAR": "6 Months",
                "YEAR": "1 Year"
            },
            buttonStatus: this.$t('SUBSCRIBE NOW'),
            planIntervalChange:"",
            localDisplayLang : "",           
            selectedOption:0,
            selectedPlan:{},
            authInfo: {},
            showAuthPopup: false
        }
    },
    created(){   
              
        
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    //     eventBus.$on("toggle-auth", () => {
    //   this.toggleAuthPopUp('lookup');
    // }); 
    this.authInfo.formType = 'lookup';
    },
    mounted(){
        console.log("availablePlans",this.availablePlans);       
        this.planIntervalChange = this.availablePlans[0].planinterval;
        this.selectedPlan = this.availablePlans[0]
    },
    methods:{
        closeAuthPopup(state) {
      this.showAuthPopup = state;
      if (this.$route.name == "sainashorts") {
        setTimeout(() => {
          document.getElementById("body").style.overflowY = "hidden";
        }, 10)
      } else {
        document.getElementById("body").style.overflowY = "auto";
      }
    },

     toggleAuthPopUp(state) {
      this.showAuthPopup = true;
      this.authInfo.formType = state;
    },
        currencyCodeSubscriber (plan) {
            return  plan.currency == "INR" ? "<span>&#8377;</span>" + " " + (plan.amount / 100) : "<span>&#36;</span>" + " " + (plan.amount / 100);
        },
        getPlanInterval(planInter) {
        return this.enumDataset[planInter] ? this.$t(this.enumDataset[planInter]) : '';
      },
        onChangePlanBenefits(plan,index){            
            this.planIntervalChange = plan.planinterval;       
            this.selectedPlan = plan;
            this.selectedOption = index
        },
        openPaymentModal(plan){
            setTimeout(() => {                
                if (this.$route.name == "seeAllPlans") {                  
                    if (this.subscriberid) {                         
                        eventBus.$emit("open-rzp-modal", plan);
                    } else {
                        // eventBus.$emit("toggle-auth");
                        this.showAuthPopup = true;
                    }
                } else {                     
                    if (this.subscriberid) {
                        eventBus.$emit("open-home-rzp-modal", plan);
                    } else {                        
                        this.showAuthPopup = true;
                        // eventBus.$emit("toggle-auth");
                    }
                }
            }, 300)
        }
    }
     
}
</script>
<style scoped lang='scss'>
@import "@/sass/_variables.scss";
@import './MobileViewPlanCard.scss'
</style>
